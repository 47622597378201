.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px dashed #ccc;
  border-radius: 5px;
  background-color: var(--main-bg-color-dark);
  height: 200px;
  width: 100%;
  text-align: center;
}

.dragging {
  border-color: #000;
}

.uploadButton {
  /* TODO: create composition with IllegalButton */
  display: inline-block;
  position: relative;
  background-color: var(--main-bg-color);
  color: var(--primary-color);
  padding: 18px 66px;
  border: thin solid var(--primary-color);
  border-radius: 50px;
  font-size: 1.2rem;
  cursor: pointer;
  transition: all 0.2s;
}

.uploadButton:hover {
  background-color: var(--main-bg-color-dark);
  color: var(--primary-color-dark);
}

.uploadButton input[type='file'] {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
