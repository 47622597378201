.button {
  display: inline-block;
  position: relative;
  background-color: var(--primary-color);
  color: var(--primary-color-contrast);
  padding: 18px 66px;
  border: thin solid var(--primary-color);
  border-radius: 50px;
  font-size: 18px;
  cursor: pointer;
  transition: all 0.2s;
}

.smol {
  border-radius: 4px;
  padding: 7px 24px;
  font-size: 16px;
}

.outlined {
  background-color: var(--main-bg-color);
  color: var(--primary-color);
}

.button:hover {
  background-color: var(--primary-color-dark);
  border-color: var(--primary-color-dark);
}

.outlined:hover {
  background-color: var(--main-bg-color-dark);
  color: var(--primary-color-dark);
}

.button:disabled {
  cursor: not-allowed;
  border-color: var(--disabled-color-text);
  background-color: var(--disabled-color);
  color: var(--disabled-color-text);
}

.smol:disabled {
  border-color: var(--disabled-color);
}

.secondary {
  background-color: var(--main-bg-color);
  border-color: var(--main-bg-color);
}
