.root {
  margin: 60px 160px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

@media (max-width: 900px) {
  .root {
    display: none;
  }
}

.button {
  margin-top: 90px;
  align-self: center;
}
