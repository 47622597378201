.section {
  position: relative;
  background: var(--paper-bg-color);
  padding: 56px 40px;
  border: 1px solid var(--paper-border-color);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.section h2,
.section h3 {
  text-align: center;
}

.section h2 {
  color: var(--primary-color-text);
  margin: 0;
  font-size: 28px;
}

.section h3 {
  color: var(--primary-color);
  margin: 4px 0 24px;
  font-weight: normal;
}

.error h3 {
  color: var(--error-color);
}
