.content {
  color: #567092;
  border: 1px solid var(--paper-border-color);
  border-radius: 10px;
  padding: 20px 24px;
  max-height: 100px;
  overflow: hidden auto;
}

.content p {
  margin: 0;
}
