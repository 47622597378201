.skeletonLoading {
  position: relative;
  width: 70%;
  height: 100%;
  overflow: hidden;
  background-color: var(--primary-color-text);
  opacity: 0.3;
  border-radius: 3px;
}

.animation {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    to right,
    var(--primary-color-skeleton-0) 0%,
    var(--primary-color-skeleton-1) 50%,
    var(--primary-color-skeleton-0) 100%
  );
  animation: skeleton-loading-animation 1.5s linear infinite;
}

@keyframes skeleton-loading-animation {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}
