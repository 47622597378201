.dotDiagram {
  display: grid;
  gap: 10px;
  align-items: center;
  grid-template-columns: 1fr 50px 1fr 50px 1fr;
}

.dotDiagram svg {
  width: 100%;
  height: 100%;
}

.dot {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 170px;
  height: 170px;
  border-radius: 50%;
  background-color: var(--primary-color-dark);
  text-align: center;
  font-size: 1.2rem;
  color: var(--primary-color-contrast);
  padding: 10px;
}
