.footer {
  margin: 180px 0 0;
  background-color: var(--primary-color-text);
}

@media (max-width: 900px) {
  .footer {
    display: none;
  }
}

.footer h1 {
  color: #ffffff;
  font-size: 64px;
}

.footer h2 {
  color: var(--primary-color);
  font-size: 36px;
}

.top {
  padding: 90px;
  display: flex;
  gap: 20px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.text > * {
  margin: 0;
}

.text > * + * {
  margin-top: 24px;
}

.contactButton {
  composes: button from '../IllegalButton/IllegalButton.module.css';
}

/* higher specificity to overcome CSS modules order issue in composition */
a.contactButton {
  border-width: thick;
  background-color: var(--dark-color);
  text-decoration: none;
  flex-shrink: 0;
}

a.contactButton:hover {
  background-color: var(--dark-color-light);
}

.bottom {
  padding: 60px 150px;
  background-color: var(--dark-color);
  color: var(--dark-color-text);
  display: flex;
  align-items: center;
  gap: 22px;
  flex-wrap: wrap;
}

.bottom a {
  text-decoration: none;
  color: var(--dark-color-text);
}

.spacer {
  flex-grow: 1;
}

.divider {
  height: 58px;
  border-left: thin solid var(--dark-color-text);
  margin: 0 14px;
}
