.header {
  margin: 60px 160px;
}

.backgroundImage {
  position: absolute;
  top: 0;
  right: 0;
  max-width: 70vw;
  z-index: -100;
}

.container h1 {
  font-size: 62px;
}

.container strong {
  font-weight: 700;
  color: var(--primary-color);
  position: relative;
  display: inline-block;
}

.container strong:after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: -15%;
  height: 7px;
  width: 130%;
  border: 4px solid var(--primary-color);
  border-color: var(--primary-color) transparent transparent transparent;
  border-radius: 50%;
}

.container p {
  font-size: 18px;
  color: var(--tertiary-color-text);
  max-width: 670px;
}

.container a {
  text-decoration: underline;
}

.container a:visited {
  color: unset;
}

.mobileMessage {
  display: none;
  color: var(--primary-color-text);
  margin-top: 170px;
  text-align: center;
}

@media (max-width: 900px) {
  .header {
    margin: 30px 20px;
  }
  .container {
    display: none;
  }
  .mobileMessage {
    display: block;
  }
}
