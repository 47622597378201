:root {
  --main-bg-color: #f5f9fe;
  --main-bg-color-dark: #fcfcfc;

  --primary-color: #3cc2d9;
  --primary-color-skeleton-0: #3cc2d900;
  --primary-color-skeleton-1: #3cc2d944;
  --primary-color-text: #081932;
  --primary-color-contrast: #ffffff;
  --primary-color-light: #25adc2;
  --primary-color-light-text: #40bbd0;
  --primary-color-lighter: #f6fafe;
  --primary-color-dark: #20a5bd;

  --secondary-color: #e9fcff;
  --secondary-color-text: #40bbd0;

  --tertiary-color-text: #7991b0;

  --dark-color: #04132a;
  --dark-color-light: #172130;
  --dark-color-text: #465771;

  --disabled-color: #f8fbff;
  --disabled-color-text: #a8b6cb;

  --error-color: #ff1616;

  --paper-bg-color: #ffffff;
  --paper-border-color: #c9d9ed;
}

body {
  margin: 0;
  font-family: 'Aeonik', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--main-bg-color);
}

button {
  font-family: 'Aeonik', sans-serif;
}
