.switch {
  display: flex;
  justify-content: center;
}

.switch button:not(button + button) {
  border-radius: 50px 0 0 50px;
  border-right: 0;
}

.switch button + button {
  border-radius: 0 50px 50px 0;
  border-left: 0;
}
