.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.content {
  position: relative;
  z-index: 1;
  background-color: white;
  padding: 20px;
  border-radius: 5px;
}

.closeBtn {
  position: absolute;
  top: 10px;
  right: 10px;
  height: 35px;
  width: 35px;
  font-weight: bold;
  background-color: transparent;
  border: none;
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.closeBtn:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
