.resultsTable {
  border-collapse: collapse;
  width: 100%;
  table-layout: fixed;
  font-size: 16px;
}

.resultsTable th {
  border-bottom: thin solid var(--primary-color);
  padding: 24px 16px;
  text-align: left;
  color: var(--primary-color);
  font-weight: 500;
  width: 50%;
  height: 19px;
}

.resultsTable tr:nth-child(2n) {
  background-color: var(--primary-color-lighter);
}

.resultsTable td {
  padding: 22px 16px;
  height: 19px;
}

.resultsTable td:first-child {
  color: var(--tertiary-color-text);
}
